<template>
  <div class="mine-course">
    <div class="mine-course-title">
      我的课程
    </div>

    <!-- 课程列表 -->
    <div class="course-content" v-loading="allCourseListLoading">
      <div class="course-content-div" v-for="(item, i) in allCourseList" :key="i" @click="clickDetails(item)">
        <div class="content-img">
          <img :src="item.smallCover" alt="">
          <div class="content-img-title-1">{{ item.year }}</div>
          <div class="content-img-title">{{ item.courseName }}</div>
          <div class="content-img-title-2">
            <el-progress :percentage="item.progress" :stroke-width="8" color="#60C6FF"></el-progress>
          </div>
        </div>
        <div class="content-line-title">{{ item.courseName }}</div>
        <div class="content-detail">
          <div style="display: flex;align-items: center;">
            <div>{{ item.type }}</div>
            <div class="line" style=""></div>
            <div>{{ item.creditHours }}学时</div>
          </div>
          <el-button class="delete" type="text" icon="cus-icon-delete-line" @click.native.stop="deleteItem(item)">删除
          </el-button>
        </div>
      </div>
      <div v-if="!allCourseList.length>0">
        暂无课程，到选课中心添加
      </div>
    </div>


    <my-dialog title="警告" :visible.sync="deleteFlag" width="22.6875rem">
      <div class="message">
        删除之后，相关学时也将对应减少，是否确认删除？
      </div>

      <el-row>
        <el-col class="flex_end_j">
          <div class="flex_cancel" @click="showFlag = false">取消
          </div>
          <div class="flex_confirm" @click="removeItem">确定
          </div>
        </el-col>
      </el-row>
    </my-dialog>

  </div>
</template>

<script>
import { httpGet,httpPost,httpNPPost} from "@/utils/httpRequest";

export default {
  name: "MineCourse",
  data() {
    return {
      // 我的课程加载开启
      allCourseListLoading:true,
      allCourseList: [
        // {
        //   year: '',
        //   title: '',
        //   type: '',
        //   hour: ''
        // },
      ],
      deleteFlag: false,
      deleteMsg: "",
    }
  },
  methods: {
    clickDetails(data) {
      // console.log(data)
      this.$router.push({ name: 'CourseDetails' ,query: data})
    },
    // 删除
    removeItem() {
      // console.log(this.deleteMsg)
      httpNPPost("/app/app-user-course-study/delete?studyId="+this.deleteMsg,
        (res) => {
          // console.log(res)
          // console.log("删除成功")
          this.deleteFlag = false;
          this.getSubMsg();
          this.getSubjects();
        },
        (err) => {
          if(err.code==500){
            if(err.msg=="token异常，请重新登录"){
              localStorage.clear();
              if(!localStorage.getItem("userId")){
                this.$message.error('登录过期，请重新登录!')
                this.$router.push("/login")
              }
            }
          }
          this.deleteFlag = false;
          // console.log(err)
          throw err
        }
      )
    },
    deleteItem(item) {
      // console.log(item)
      this.deleteMsg = item.studyId;
      this.deleteFlag = true;
    },
    // 获取课程信息
    getSubMsg() {
      httpGet("/app/app-user-course-study/list",
        {
          userId: localStorage.getItem('userId'),
        },
        (res) => {
          // console.log(res.data)
          this.allCourseList = res.data;
          this.allCourseListLoading = false;
        }, (err) => {
          if(err.code==500){
            if(err.msg=="token异常，请重新登录"){
              localStorage.clear();
              if(!localStorage.getItem("userId")){
                this.$message.error('登录过期，请重新登录!')
                this.$router.push("/login")
              }
            }
          }
          // console.log(err)
          throw err
        })
    }
  },
  mounted() {
    this.getSubMsg();
  },
}
</script>

<style lang="scss" scoped>
.mine-course {
  padding: 0 3.38624%;
  background: #FFFFFF;
   border-radius: 10px;
  height: 49.5rem;

  .message {
    font-size: 1.125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5rem;
    text-align: left;
    padding: 0.75rem 6.61157% 3rem 8.8154%;
    ;
  }

  .message::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('~@/assets/img/pre/icon-tip-1.png');
    background-size: 100% 100%;
    margin-right: 1.101928%;
  }

  .flex_end_j {
    display: flex;
    justify-content: flex-end;
    padding-right: 4.4077%;
    height: 3.25rem;
    border-top: 0.0625rem solid #EDEFF2;
    align-items: center;

    .flex_cancel {
      width: 15.97796%;
      height: 2rem;
      background: #FFFFFF;
      border-radius: 10px;
      opacity: 1;
      border: 0.0625rem solid #E6E8EB;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3E454D;
      line-height: 2rem;
      margin-right: 2.7548%;
      cursor: pointer;
    }

    .flex_confirm {
      width: 15.97796%;
      height: 2rem;
      background: #1371E7;
      box-shadow: 0px 0.25rem 0.375rem 0px rgba(19, 113, 231, 0.08);
      border-radius: 10px;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 2rem;
      cursor: pointer;
    }
  }


  .course-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 31.782%);
    justify-content: space-between;
    height: 42.5rem;
    // overflow-y: scroll;
    margin-top: 1.25rem;
    overflow-x: hidden;
    .delete {
      color: #D65B6B;
      display: none;
      transition-delay: .1s;
    }

    .course-content-div:hover .delete {
      display: flex;
      align-items: center;
      transition-delay: .1s;
      z-index: 999;
    }

    .course-content-div {
      width: 100%;
      height: 16.25rem;
      background: #FFFFFF;
           border-radius: 10px;
      overflow: hidden;
      margin-bottom: 1.75rem;
      cursor: pointer;
      border: 0.0625rem solid #EDEFF2;

      .content-img {
        position: relative;
        width: 100%;
        height: 9.6875rem;
        background-color: red;

        img {
          width: 100%;
          height: 100%;
        }

        .content-img-title {
          position: absolute;
          top: 40%;
          left: 15%;
          width: 71.42857%;
          font-size: 1.0625rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 1.25rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .content-img-title-1 {
          position: absolute;
          top: 28%;
          left: 15%;
          width: 71.42857%;
          font-size: 1.0625rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 1.25rem;
        }

        .content-img-title-2 {
          margin-top: -1.5rem;
          height: 0.5rem;
        }

        /* 当前状态颜色 */
        ::v-deep .el-progress-bar__outer {
          background: rgba(1, 1, 1, 0.23);
        }

        ::v-deep .el-progress-bar {
          height: 1rem;
        }

        ::v-deep .el-progress__text {
          display: inline-block;
          vertical-align: middle;
          height: 1.5rem;
          font-size: 0.8125rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          margin-left: 0.625rem;
          line-height: 1;
        }
      }

      .content-line-title {
        margin: 0.625rem 6.42857% 0.8125rem 6.42857%;
        ;
        height: 3.25rem;
        font-size: 1rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 1.625rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
      }

      .content-detail {
        margin: 0.625rem 6.42857% 0.8125rem 6.42857%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.25rem;
        font-size: 0.75rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 1.25rem;

        .line {
          width: 0.0625rem;
          height: 0.5rem;
          background: #EDEFF2;
          margin: 0 1rem;
        }
      }
    }
  }


  .mine-course-title {
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    font-size: 1.25rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #EDEFF2;
  }
}
</style>